<template>
  <main class="main page newproject PUrchaseSUccess">
    <div class="quiz purchase-success">
      <div class="quiz-content">
        <div class="quiz__project-name">{{ targetSurvey ? targetSurvey.projectName : 'My Room' }}</div>
        <div style="grid-column: span 12; text-align: center;">
          <h1 class="quiz__heading">
            Order Complete!
          </h1>
          <div class="quiz__progress"></div>
          <h2 class="heading__greensubheading">
            Order ID: {{ userProfile && userProfile.stripeOrderCompleteId || '' }}
          </h2>
        </div>
        <div class="purchase-success__image">
          <img src="@/assets/images/success.png">
        </div>
        <div class="form__item">
          <HeadingTt>
            Thanks for your purchase – we're on it.<br>
            You'll receive information about shipping shortly.<br>
            In the meantime, let's get started on your next room!
            <!-- In the meantime, refer a friend and we’ll offer a discount on future orders with toTree. -->
          </HeadingTt>

          <div class="success__actions">
            <router-link to="/orders">
              <button class="btn-Border">
                No thanks, I'm good
              </button>
            </router-link>
            <router-link to="/quiz">
              <button class="button customGreenBTN">I'm ready, let's do it</button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { defineComponent } from 'vue'
import { mapState, mapGetters } from 'vuex'

export default defineComponent({
  name: 'PurchaseSuccess',
  computed: {
    ...mapState(['completedOrder']),
    ...mapGetters(['targetSurvey', 'userProfile'])
  }
})
</script>

<style lang="scss" scoped>
.purchase-success {
  text-align: center;

  img {
    max-height: 300px;
  }

  .success__actions {
    grid-column: span 12;
    margin-top: 3rem;
  }
}
</style>
